<template>
  <div>
    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="search">
            <i class="fa fa-search"></i> 검색
          </b-button>
        </b-input-group-prepend>
        <b-form-input id="search" type="text" placeholder="상품명, goods_id, src_id, sku_id를 넣어주세요" v-model="form.search" @keypress.enter.prevent.stop="search" v-focus></b-form-input>
        <!--<b-input-group-append>
          <b-button variant="light" v-b-toggle.collapse1>검색조건 <i class="fa fa-chevron-down"></i></b-button>
        </b-input-group-append>-->
      </b-input-group>
      <b-row class="mb-2">
        <b-col lg="2">
          <small>Shop ID</small>
          <b-form-input type="text" placeholder="" v-model="form.shop_id" @keypress.enter="search"></b-form-input>
        </b-col>
        <b-col lg="2">
          <small>이미지순서</small><br/>
          <b-form-radio-group class="col-form-label" v-model="form.image_orderby" :options="[
              {text: '정순', value: 'normal'},
              {text: '역순', value: 'reverse'},
            ]">
          </b-form-radio-group>
        </b-col>
        <b-col lg="1">
          <small>이미지 앞 제거</small><br/>
          <b-form-input type="text" v-model.number="form.remove_pre_image"></b-form-input>
        </b-col>
        <b-col lg="1">
          <small>이미지 뒤 제거</small><br/>
          <b-form-input type="text" v-model.number="form.remove_post_image"></b-form-input>
        </b-col>
        <b-col lg="2">
          <small>이미지 수</small><br/>
          <b-form inline>
            <b-form-input class="w-50px" type="text" v-model.number="form.image_length_st"></b-form-input>
            ~
            <b-form-input class="w-50px" type="text" v-model.number="form.image_length_ed"></b-form-input>
          </b-form>
        </b-col>
        <b-col lg="1">
          <small>이미지 한도</small><br/>
          <b-form-input type="text" v-model.number="form.image_limit"></b-form-input>
        </b-col>
        <b-col lg="3">
          <small>유형</small><br/>
          <b-form-radio-group class="col-form-label" v-model="form.orderby" :options="[
              {text: '최신순', value: '_id'},
              {text: 'Goods ID', value: 'goods_id'},
              {text: '랜덤샘플링', value: 'random'},
            ]">
          </b-form-radio-group>
        </b-col>
      </b-row>

      <b-form-checkbox-group v-model="fields_selected">
        <b-form-checkbox v-for="f in fields_org" :key="f.key" :value="f.key">{{f.label.removeTags()}}</b-form-checkbox>
      </b-form-checkbox-group>

      <b-collapse id="collapse1">
        <b-row class="mb-2">
          <b-col md="3">
            <small>한 번에 가져올 상품 수</small>
            <b-form-input type="text" placeholder="한 번에 가져올 상품 수" v-model.number="form.limit"></b-form-input>
          </b-col>
          <b-col md="3">
            <small>총 재고합이 n 개 이상</small>
            <b-form-input type="text" placeholder="options 의 재고의 합이 이 숫자 이상입니다" v-model.number="form.tot_stock"></b-form-input>
          </b-col>
          <b-col md="3">
            <small>재고가 n 개 이상인 옵션이 존재</small>
            <b-form-input type="text" placeholder="options 의 재고가 n개 이상인 옵션이 존재합니다" v-model.number="form.opt_stock"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <small>goods_id</small>
            <b-form-textarea :rows="2" v-model="form.goods_id" placeholder="goods_id를 입력해주세요"></b-form-textarea>
            <small class="text-muted">엔터로 구분된 goods_id를 입력해주세요</small>
          </b-col>
        </b-row>
      </b-collapse>
      <b-button class="m-1" variant="primary" @click="search">검색</b-button>
      <b-button class="m-1" variant="outline-success" v-b-toggle.collapse1>상세검색조건</b-button>
    </b-card>

    <b-modal title="Mapped" size="xl" v-model="modal">
      <div class="clearfix">
        <h5 v-if="item.src_not_found">
          <small class="text-muted"><i class="fa fa-exclamation-circle"></i> {{item.not_found._dt}} 이후로 원본정보에서 찾을 수 없습니다</small>
        </h5>
      </div>
      <div class="mb-2">
        <b-badge variant="success">{{item.shop_id}}. {{item.shop}}</b-badge>
        <b-badge class="ml-1" variant="warning">{{item.brand_name}}</b-badge>
        <b-badge class="ml-1" variant="secondary">{{item.category}}</b-badge>
        <b-badge class="ml-1" variant="light">{{item.origin_category}}</b-badge><br/>
        {{item.name}}
      </div>
      <div class="mb-4">
        <small>이미지</small><br/>
        <div class="mt-3">
          <span v-for="(i, idx) in item.img_urls">
            <img :id="`mapped_imgs-${idx}`" :src="i" style="height:80px" @click="utils.open(`${i}`)"/>
            <b-popover :target="`mapped_imgs-${idx}`" placement="bottom" triggers="hover focus">
              <img :src="i" style="max-width:250px"/>
            </b-popover>
          </span>
        </div>
      </div>
      <b-row class="mb-2">
        <b-col cols="6" lg="3">
          <small>가격</small><br/>
          <div class="text-right pr-3">
            <div v-if="item.discount_rate > 0"><del>{{utils.rnc(item.price / (100-item.discount_rate) * 100, 2)}}</del> {{item.price_unit}}</div>
            <div>{{utils.rnc(item.price, 2)}} {{item.price_unit}}</div>
            <div v-if="item.discount_rate > 0">({{item.discount_rate}} %)</div>
          </div>
        </b-col>
        <b-col cols="6" lg="4">
          <div v-if="item.options && item.options.length">
            <small>{{item.options[0].optnm}}</small><br/>
            <template v-for="e in item.options">
              <b-badge variant="light">{{e.Size}}</b-badge><b-badge variant="success">{{e.stock}}</b-badge>
              &nbsp;
              <span v-if="e.goods_price !== item.price"><small>{{utils.rnc(e.goods_price, 2)}} {{item.price_unit}} ({{utils.delta(e.goods_price - item.price, 2)}} {{item.price_unit}})</small></span>
              <span v-else class="text-muted"><small>(가격동일)</small></span>
              <br/>
            </template>
          </div>
        </b-col>
        <b-col cols="6" lg="5">
          <small>상품코드</small><br/>
          <small>SKU : </small><a :href="`https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${(item.sku_id||'').replace(' ', '+')}`" target="_blank"><b-badge variant="success">{{item.sku_id}}</b-badge></a><br/>
          <small>파트너 상품코드 : </small>
          <a v-if="item.crawl_url && item.crawl_url.startsWith('http')" :href="`https://hub.balaan.io/r?u=${encodeURIComponent(item.crawl_url)}`" target="_blank"><b-badge variant="info">{{item.goods_id || 'link'}}</b-badge></a>
          <b-badge v-else variant="light">{{item.goods_id}}</b-badge>&nbsp;<i class="fa fa-question-circle" v-b-tooltip="'파트너 상품링크가 있다면 파란색으로 표시되며 클릭하면 새 창으로 띄워집니다.'"></i><br/>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="3">
          <small>컬러</small><br/>
          {{item.color || '-'}}
        </b-col>
        <b-col cols="3">
          <small>시즌</small><br/>
          {{item.launch_date || '-'}}
        </b-col>
        <b-col cols="3">
          <small>원산지</small><br/>
          {{item.origin || '-'}}
        </b-col>
        <b-col cols="3">
          <small>성별</small><br/>
          {{item.generation || '-'}}
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12">
          <small>소재</small><br/>
          <span v-html="item.composition || '-'"></span>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12">
          <small>measurements</small><br/>
          <span v-html="item.measurements || '-'"></span>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12">
          <small>modelsize</small><br/>
          <span v-html="item.modelsize || '-'"></span>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12">
          <small>설명</small><br/>
          <span v-html="item.description || '-'"></span>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="3">
          <small>최초등록일</small><br/>
          {{item._cdt}}
        </b-col>
        <b-col cols="3">
          <small>최근확인시각</small><br/>
          {{item._dt}}
        </b-col>
      </b-row>
      <b-button v-if="item.src" variant="light" @click="jsonModal('Src 보기', item.src)">SRC 확인</b-button>
      <template v-slot:modal-footer="{ ok }">
        <b-button v-if="item.price_table && item.price_table.length" class="ml-2" variant="light" @click="jsonModal('원가테이블 확인', item.price_table)">
          원가정보 확인<span v-if="item.price_table.length > 1">({{item.price_table.length}} 종류)</span>
        </b-button>
        <b-button variant="outline-light" @click="jsonModal('JSON 보기', item)">
          JSON
        </b-button>
        <b-button v-if="item._diff && item._diff.length" variant="outline-light" @click="showDiffModal(item)">
          수정이력
        </b-button>
        <b-button variant="primary" @click="ok()">
          닫기
        </b-button>
      </template>
    </b-modal>

    <c-table small :table-data="items" :fields="fields" :perPage.sync="perPage" :isBusy="busy.search" :getMoreBusy="busy.searchMore" :hasMore="hasMore.search" :caption="items.length + ' 개 상품'" @btn-clicked="btnAction" @get-more="search(true)"></c-table>
  </div>
</template>

<script>
import {getMeta, getJson, postJson} from '@/shared/api'
import * as utils from '@/shared/utils'
import {MALL_GOODS_LINK, ORDER_STATUS_CODE} from 'balaan_constants'
import * as moment from 'moment-timezone';
import * as momentBiz from 'moment-business-days';
import store from '@/store';
const {state:S, getters:{R}} = store;
import {Types} from 'mongoose';

export default {
  name: 'MappedImageCheck',
  data() {
    return {
      utils, MALL_GOODS_LINK, ORDER_STATUS_CODE, momentBiz, S, R,
      statusCodeMap: {},
      shop: [],
      shopMap: {},
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      form: {
        search: '',
        goods_id: '',
        tot_stock: '',
        opt_stock: '',
        shop_id: '',
        image_orderby: 'normal',
        remove_pre_image: 0,
        remove_post_image: 0,
        image_limit: 99,
        image_length_st: '',
        image_length_ed: '',
        orderby: 'random',
        skip: 0,
        limit: 100,
      },
      lastForm: {},
      title: '',
      items: [],
      item: {},
      fields_org: [
        {key: 'html1', label:'Mapped 이미지'},
        {key: 'html2', label:'Src 이미지'},
        {key: 'html7', label:'상품정보', style:{maxWidth:'400px'}},
        {key: 'html8', label:'<span class="badge badge-light">Goods ID</span><br/><span class="badge badge-success">Sku ID</span><br/>', class: 'text-center'},
        {key: 'html9', label:'가격', class: 'text-right'},
        {key:'_actions', label:'상세', style:{width:'55px', textAlign:'center'}, buttons: [{label:'상세', event:'show_modal'}]},
      ],
      fields: [],
      fields_selected: [],
      perPage: 10,
      busy: {search: false, searchMore: false},
      hasMore: {search: false},
      modal: false,
      diffListModal: false,
      diff: null,
      today: moment().format('YYYY-MM-DD'),
      deliveryDay: 6,
      orders: [],
      reviews: [],
      abortController: null,
      ac: {search: null}, // abortController
    }
  },
  sockets: {
    mapped_query(q) {
      console.log(q);
    }
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'perPage');
    this.fields = this.fields_org;
    this.fields_selected = this.fields.map(e=>e.key);

    ORDER_STATUS_CODE.forEach(e=>this.statusCodeMap[e.code] = e);
    let meta = await getMeta('shop,brand,category,holiday');
    if (!meta) return;

    // 공휴일 설정
    let holidays = meta.holiday.map(e=>{
      if (e.require) return momentBiz().format('YYYY-') + e.date;
      return e.date;
    });
    // 작년, 내년도 추가한다
    holidays = holidays.concat(meta.holiday.filter(e=>e.require).map(e=>momentBiz().add(1, 'year').format('YYYY-') + e.date));
    holidays = holidays.concat(meta.holiday.filter(e=>e.require).map(e=>momentBiz().subtract(1, 'year').format('YYYY-') + e.date));
    momentBiz.updateLocale('kr', {
      holidays: holidays,
      holidayFormat: 'YYYY-MM-DD'
    });
    window.moment = momentBiz;

    this.shop = meta.shop.sort((a,b)=>a.shop_id-b.shop_id);
    this.shop.forEach(s=>{
      s.value = s.boutique;
      s.label = `${s.shop_id}. ${s.boutique}`;
      this.shopMap[s.shop_id] = s;
    });

    this.brand = meta.brand.map(e=>{
      return this.brandMap[e.brand_no] = {...e, value:e.brand_no, label:`${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a,b)=>a.label.localeCompare(b.label));

    this.category = meta.category.map(e=>{
      return this.categoryMap[e.category] = {...e, value:e.category, label:`${e.category} (${e.category_nm})`};
    }).sort((a,b)=>(a.value.length - b.value.length)*10 + a.value.localeCompare(b.value));

    const id = this.$route.params.id;
    if (id) {
      this.form.search = id;
      let item = this.items.filter(e=>e.goods_id === id || e.src_id === id || e.sku_id === id)[0];
      if (item) this.showModal({item});
    }
  },
  async beforeDestroy() {
    Object.values(this.ac).filter(e=>e).forEach(e=>e.abort());
  },
  watch: {
    form: {
      deep: true,
      handler(v) {
        this.items.forEach(e=>{
          this.adjustImage(e, v);
        });
      },
    },
    fields_selected: function() {
      this.fields = this.fields_org.filter(e=>this.fields_selected.includes(e.key));
    },
    perPage: function() {
      this.$utils.setStatus(this.$options.name, this, 'perPage');
    }
  },
  methods: {
    async search(more) {
      if (!this.form.shop_id) {
        return utils.alert('shop_id 는 필수입니다.');
      }
      let form;
      if (more === true) {
        if (this.busy.searchMore) return; // 이전요청을 기다린다
        form = this.lastForm;
        form.skip += form.limit;
        this.busy.searchMore = true;
      } else {
        form = this.form;
        this.lastForm = utils.clone(form);
        this.busy.search = true;
      }

      let j, body = this.makeFormData(form);
      this.ac.search && this.ac.search.abort();
      this.ac.search = new AbortController();
      try {
        j = await postJson('/goods/mapped', body, {signal:this.ac.search.signal});
      } catch (e) {
        this.items = [];
        return;
      } finally {
        this.ac.search = null;
      }
      j.list.forEach(this.assignTableData);

      this.hasMore.search = j.hasMore;
      if (more === true) {
        this.items = this.items.concat(j.list);
        this.busy.searchMore = false;
      } else {
        this.items = j.list;
        this.busy.search = false;
      }
    },
    makeFormData(form) {
      const shop = form.shop_id ? form.shop_id.split(',').map(e => +e) : []; // 전체 선택일 경우 비우기
      const goods_id = form.goods_id.trim().split(/\r?\n/g).map(e => e.trim()).filter(e => e);
      return {...form, shop, goods_id};
    },
    adjustImage(e, v) {
      let imgs = e.img_urls.slice(0 + v.remove_pre_image, e.img_urls.length - v.remove_post_image);
      let others = imgs.length - v.image_limit;
      imgs = imgs.slice(0, v.image_limit).map((img, i) => `<a href="${img}" target="_blank"><img id="${e.goods_id}_${i}" src="${img}" style="max-height:80px;max-width:80px"/></a>`);
      if (v.image_orderby === 'reverse') imgs.reverse();
      e.html1 = imgs.join('') + (others > 0 ? `<br/>외 ${others} 개 이미지` : '');
      e.html2 = 'comment_img,img_list,als_wrapper_img'.split(',')
          .concat(e._src && 'comment_img,img_list,als_wrapper_img'.split(',').some(c=>e._src[c]) ? [] : ['img_urls'])
          .map(c=>{
        if (!e._src || !e._src[c]) return;
        let imgs = e._src[c].slice(0 + v.remove_pre_image, e._src[c].length - v.remove_post_image);
        let len = imgs.length;
        imgs = imgs.slice(0, v.image_limit).map((img, i) => `<a href="${img}" target="_blank"><img id="${e.goods_id}_${i}" src="${img}" style="max-height:80px;max-width:80px"/></a>`);
        if (v.image_orderby === 'reverse') imgs.reverse();
        return `${c}: 총 ${len} 개 이미지<br/>` + imgs.join('');
      }).filter(e=>e).join('<br/>');
    },
    assignTableData(e) {
      if (this.lastForm.minimal) {
        e.html7 = `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. </a> <span class="badge badge-light">${e.goods_id}</span>`;
        return;
      }
      if (!e.consumer) e.consumer = e.price;
      e._cdt = e._cdt || utils.kstDT(Types.ObjectId(e._id).getTimestamp());
      e.shop = this.shopMap[e.shop_id] && this.shopMap[e.shop_id].boutique;
      // Array(7).fill(0).forEach((_,i)=>{
      //   e[`html${i+1}`] = e.img_urls[i] ? `<img id="${e.goods_id}_${i}" src="${e.img_urls[i]}" style="height:60px"/>` : '';
      // });
      // e.html1 = e.img_urls.slice(0,7).map((img,i)=>`<img id="${e.goods_id}_${i}" src="${img}" style="height:60px"/>`).join('');

      this.adjustImage(e, this.form);

      let optionsHtml = `<small><b>${e.options && e.options.length ? e.options[0].optnm : ''}</b></small>
        ${e.options.slice(0, 10).map(e=>`<span class="badge badge-light">${e.Size}</span><span class="badge badge-success">${e.stock}</span>`).join(', ')}`
        + (e.options.length > 10 ? `외 ${e.options.length - 10}개` : '');
      e.html7 = `<a href="/#/shop/${e.shop_id}" target="_blank" class="badge badge-success">${e.shop_id}. ${e.shop}</a> <span class="badge badge-warning">${e.brand_name}</span>`
        + `<span class="badge badge-light">${e.category}</span> <span class="badge badge-light">${e.origin_category}</span> `
        + (e.goods_no ? `<a href="/#/goods/${e.goods_no}" target="_blank" class="badge badge-primary">${e.goods_no}</a>` : '') + `<br/>`
        + `${e.name}<br/>${optionsHtml}`
        + (e.delivery_type ? `<br/><span class="badge alert-${e.delivery_type === '해외' ? 'success' : 'info'}">${e.delivery_type}</span>` : '');
      e.html8 = (e.crawl_url && e.crawl_url.startsWith('http') ? `<a href="${`https://hub.balaan.io/r?u=${encodeURIComponent(e.crawl_url)}`}" target="_blank" class="badge badge-info">${e.goods_id}</a>` : `<span class="badge badge-light">${e.goods_id}</span>`)
        + `<br/><a href="https://search.shopping.naver.com/search/all.nhn?where=all&frm=NVSCTAB&query=${encodeURIComponent(e.sku_id)}" target="_blank" class="badge badge-success">${e.sku_id}</a><br/>`;
      e.html9 = (e.consumer === e.price ? '' : `<del>${e.consumer ? utils.rnc(e.consumer, 2) : ''} ${e.price_unit && e.price_unit !== 'KRW' ? e.price_unit : ''}</del><br/>`)
        + `${e.price ? utils.rnc(e.price, 2) : ''} ${e.price_unit && e.price_unit !== 'KRW' ? e.price_unit : ''}<br/>` + (e.consumer === e.price ? '' : `(${100-Math.round(e.price/e.consumer*100)}%)`);
    },

    showModal(row) {
      let item = this.item = row.item;
      this.modal = true;
      this.orders = [];
      this.reviews = [];

      // _diff 가 있다면 최근 가격변경, 재고변경일, src_not_found 를 가져온다
      if (item._diff && item._diff.length) {
        item.stock_diff = item._diff.filter(e=>e.tot_stock).reverse()[0];
        item.price_diff = item._diff.filter(e=>e.price).reverse()[0];

        item.not_found = item._diff.filter(e=>e.src_not_found).reverse()[0];
      }
      postJson('/goods/src', {form:{shop_id:item.shop_id, src_id:item.src_id}}).then(j=>{
        if (!j) return;
        this.item.src = j.list;
        this.$forceUpdate();
      });
    },
    showDiffModal(item){
      this.$modal.show({title: '수정이력 확인', type: 'diff', item: item, db: 'secondDB', collection: 'mapped'});
    },
    btnAction (row, event) {
      if (event === 'show_modal') {
        this.showModal(row);
      }
    },
    jsonModal(title, json) {
      this.$modal.show({title, type:'json', item:json});
    },
  }
}
</script>
